<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import PurchaseBalanceModalV1 from '/~/components/drawer/modals/menu-epurchase-balance-modal.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'profile-epurchase-balance-tracker-modal-v2',
  components: {
    BaseIcon,
  },
  extends: PurchaseBalanceModalV1,
  setup() {
    const { isDarkThemeForEwallet } = useCms()
    const { providerCurrency } = useProvider()

    return {
      ...PurchaseBalanceModalV1.setup?.(...arguments),
      isDarkThemeForEwallet,
      providerCurrency,
    }
  },
}
</script>

<template>
  <drawer-modal :to="to" :visible="visible" @hide="hide">
    <div
      class="-mx-5 px-5 pt-5"
      :class="{
        'bg-gray-900 text-white': isDarkThemeForEwallet,
      }"
    >
      <div
        class="mb-5 flex h-[300px] items-center justify-center bg-center bg-no-repeat pt-2.5"
        :style="{
          backgroundImage: `url('/icons/v3/empty-blue.svg')`,
        }"
      >
        <div
          class="flex h-[120px] w-[120px] items-center justify-center rounded-full bg-primary text-white"
        >
          <base-icon svg="v2/heroic/pencil-alt" :size="75" />
        </div>
      </div>
      <div
        class="mt-5 text-center text-2xl font-bold"
        :class="{
          'text-eonx-neutral-800': !isDarkThemeForEwallet,
          'text-white': isDarkThemeForEwallet,
        }"
      >
        Balance Tracker
      </div>
      <div
        class="mt-4 text-center"
        :class="{
          'text-eonx-neutral-600': !isDarkThemeForEwallet,
          'text-white': isDarkThemeForEwallet,
        }"
      >
        Please update your remaining balance manually. For a live balance simply
        ask the cashier, refer to the retailer’s website or contact their
        customer service team.
      </div>
      <div class="relative mx-auto mt-4 w-[174px] max-w-full">
        <div class="flex h-12 w-[174px] items-center rounded-md border">
          <div
            class="flex h-full items-center rounded-l-md pl-4 pr-2.5 text-center"
            :class="{
              'text-eonx-neutral-600': !isDarkThemeForEwallet,
              'text-white': isDarkThemeForEwallet,
            }"
          >
            $
          </div>
          <base-currency-input
            v-model="inputValue"
            :value-range="{ min, max }"
            :currency="null"
            class="w-20 pr-2.5"
            @keyup="onKeyup"
          />
          <div
            :class="{
              'text-eonx-neutral-600': !isDarkThemeForEwallet,
              'text-white': isDarkThemeForEwallet,
            }"
            data-testid="provider-currency"
          >
            {{ providerCurrency }}
          </div>
        </div>
        <div v-if="inputError" class="text-center text-sm text-fg-error">
          {{ inputError }}
        </div>
      </div>

      <div class="grid w-full grid-cols-2 gap-x-2.5 py-5">
        <base-button
          look="outlined-color"
          class="w-full text-primary"
          @click="hide"
        >
          Cancel
        </base-button>
        <base-button
          :disabled="disabled"
          :loading="submitting"
          full-width
          @click="handleUpdateClick"
        >
          Update balance
        </base-button>
      </div>
    </div>
  </drawer-modal>
</template>
